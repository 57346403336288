import { Component, Input, OnInit } from '@angular/core';
import { ElementType, IContentElement } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';

@Component({
  selector: 'element-render',
  templateUrl: './element-render.component.html',
  styleUrls: ['./element-render.component.scss']
})
export class ElementRenderComponent implements OnInit {

  @Input() contentElement: IContentElement;
  @Input() isLocked: boolean;
  @Input() questionState: any;
  @Input() isShowSolution?: boolean;
  @Input() allowSubtitles?: boolean;
  @Input() allowTranscripts?: boolean;
  @Input() allowAudioPlaybackSpeed?: boolean;
  @Input() allowVideoPlaybackSpeed?: boolean;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() frameWorkTags:{slug:string}[];
  
  ElementType = ElementType;

  constructor() { }

  ngOnInit() {
  }
  
  isSelectedEdit() {
    return this.questionPubSub?.selectedEntry?.id && this.questionPubSub?.selectedEntry?.id === this.contentElement.entryId;
  }

  getBorder() {
    if(this.isSelectedEdit()) {
      return this.questionPubSub?.selectedEntry?.border || 0;
    } else {
      return 0;
    }
  }
}
