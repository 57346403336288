import { Injectable } from '@angular/core';
import { AuthService, IUserInfo } from '../api/auth.service';
import { BehaviorSubject } from 'rxjs';
import { RoutesService } from '../api/routes.service';
import { UserRoles } from '../api/models/roles';

type RolesList = string[];

export interface ITestWindow {
  id: number,
  test_ctrl_group_id: number,
  created_on?: string,
  create_by_uid?: number,
  dateEnd?:any; // from dashboard
  date_end?:  string,
  dateStart?:any; // from dashboard
  date_start?:  string,
  is_active?: number,
  is_qa?: number,
  is_bg?: number,
  is_sample?: number,
  is_allow_new_bookings?: number,
  title?: string,
  type_slug?: string,
  numSessionsCreated?:any; // from dashboard
  numSessionsAdministered?:any; // from dashboard
  numResultsPendingRelease?:any; // from dashboard
  captionStr?:any; // from dashboard
}

@Injectable({
  providedIn: 'root'
})
export class MyCtrlOrgService {

  // services + init
  constructor(
    private auth: AuthService,
    private routes: RoutesService,
  ) {
    this.auth.user().subscribe(v => {
      this._userInfo = v;
      this.loadMyRoles();
    });
  }

  // vars (these should never be public)
  private _userInfo:IUserInfo;
  private _roles:RolesList;
  private info: BehaviorSubject<RolesList> = new BehaviorSubject(null);
  public selectedWindow: ITestWindow;

  // public methods
  public sub() {
    return this.info;
  }

  getMyUid(){
    if (this._userInfo){
      return this._userInfo.uid
    }
  }

  hasRole(role:UserRoles){
    if (this._roles && this._roles.indexOf(role) !== -1){
      return true;
    }
    return false;
  }

  private loadMyRoles(){
    return this.auth
      .apiFind(this.routes.TEST_CTRL_ROLES)
      .then(roles => {
        this._roles = roles;
        this.info.next(this._roles);
      })
  }

  constructPermissionsParams(query?:any, window?:ITestWindow){
    const windowParams:any = {};
    window = window || this.selectedWindow
    if (window){
      windowParams.tc_group_id = window.test_ctrl_group_id;
      windowParams.test_window_id = window.id;
      windowParams.type_slug = window.type_slug;
    }
    return {
      query:{
        ... query,
        ... windowParams,
      }
    }
  }

  loadWindows(){
    return this.auth.apiFind(
      '/public/test-ctrl/test-window/administration-windows',
    )
  }

  getCodebookFiles(slug:string){
    return this.auth.apiFind(
      '/public/test-ctrl/schools/data-dl/codebook-tcv',
      this.constructPermissionsParams({slug})
    )
  }

  getTwFiles(){
    return this.auth.apiFind(
      '/public/test-ctrl/tw-data-file',
      this.constructPermissionsParams()
    )
  }

  async getHFTCounts(rows: any[], forPublishedTDTable: boolean): Promise<any[]>
  {
    // for BC

    if (rows.length === 0)
    {
      return rows;
    }

    let testDesignIDKeyname = "";
    if (forPublishedTDTable)
    {
      testDesignIDKeyname = "id";
    }

    else 
    {
      testDesignIDKeyname = "test_design_id";
    }

    const testDesignIDs = rows.map(row => row[testDesignIDKeyname]);
    console.log(testDesignIDs);
    let queryParams =
    {
      query:
      {
        getSimTags: 1,
        testDesignIDs
      }
    };
    
    let results: any[] = await this.auth.apiFind('/public/test-ctrl/test-designs', queryParams);

    for (let row of rows)
    {
      // console.log(+each.test_design_id);
      const foundRowIdx = results.
      findIndex(result => +result.test_design_id === +row[testDesignIDKeyname]);

      if (foundRowIdx === -1)
      {
        // no similarity tags found for test design ID (and thus the twtdar row)
        row.num_sim_tags = 0;
      }

      else
      {
        // sim tag found
        row.num_sim_tags = results[foundRowIdx].num_sim_tags;
      } 
    }
    
    return rows;
  }

  async getFullTQRData(rows: any[], forPublishedTDTable: boolean): Promise<any[]>
  {
    // for BC

    if (rows.length === 0)
    {
      return rows;
    }

    let testDesignIDKeyname = "";
    if (forPublishedTDTable)
    {
      testDesignIDKeyname = "id";
    }

    else 
    {
      testDesignIDKeyname = "test_design_id";
    }
      
    let promises: Promise<any>[] = [];
    for (let row of rows)
    {
      row.TQRCount = 0;
      row.TQRData = [];
      promises.push(this.getTQRParams(row[testDesignIDKeyname], row.id, true));
    }

    let results = await Promise.all(promises);
    for (let each of results)
    {
      // console.log(+each.twtdarId);
      const foundRowIdx = rows.findIndex(row => +row.id === +each.uniqueID);

      if (foundRowIdx === -1)
      {
        console.error("Issue in finding " + each.twtdarId + " in list of twtdars."); 
        continue;
      }
      
      rows[foundRowIdx].TQRCount = each.data.length;
      rows[foundRowIdx].TQRData = each.data;
    }

    return rows;
  }

  async getTQRParams(testDesignId: number, uniqueID: number, isGrad: boolean): Promise<any[]>
  {
  // this function returns certain columns from `test_window_td_alloc_rules`, as the type
  // depending on if grad or FSA.

  // uniqueID can be any unique identifier; e.g. twtdarID and test_design_id are examples of IDs
  // being passed in as the uniqueID
      const params =
      {
          query:
          {
              isGrad: isGrad ? 1 : 0,
              isExport: 0,
              testDesignId,
              uniqueID,
              getTQR: 1
          }
      };

      return await this.auth.apiFind(this.routes.TEST_CTRL_TQR_EXTRACT, params);
  }

  getTwTdAr(){
    // note: does not injest test window id as it is based on the selection stored in the service
    return this.auth.apiFind(
      '/public/test-ctrl/test-designs',
      this.constructPermissionsParams()
    )
  }
  createTwTdAr(insertData:any){
    return this.auth.apiCreate(
      '/public/test-ctrl/test-designs',
      insertData,
      this.constructPermissionsParams()
    )
  }
  removeTwTdAr(twtarId:number){
    return this.auth.apiRemove(
      '/public/test-ctrl/test-designs',
      twtarId,
      this.constructPermissionsParams()
    )
  }
  patchTwTdAr(twtarId:number, patchData:any){
    return this.auth.apiPatch(
      '/public/test-ctrl/test-designs',
      twtarId,
      patchData,
      this.constructPermissionsParams()
    )
  }

  getTwTdArTestDesigns(source_item_set_id?:number){
    // note: does not injest test window id as it is based on the selection stored in the service
    return this.auth.apiGet(
      '/public/test-ctrl/test-designs',
      source_item_set_id || -1, // -1 is interpreted as wanting to take from all available published test designs
      this.constructPermissionsParams()
    )
  }

  assignTwTdAr(twTdArId:number, test_design_id:number){
    return this.auth.apiPatch(
      '/public/test-ctrl/test-designs',
      twTdArId,
      {test_design_id},
      this.constructPermissionsParams()
    )
  }

  getSummaries(col:string, window?:ITestWindow){
    return this.auth.apiGet(
      '/public/test-ctrl/schools/summary',
      0,
      this.constructPermissionsParams({ 
        col
      }, window)
    )
  }
  
  getBoards(){
    return this.auth.apiFind(
      '/public/test-ctrl/schools/boards',
      this.constructPermissionsParams({})
    )
  }
  getSchools(){
    return this.auth.apiFind(
      '/public/test-ctrl/schools/schools',
      this.constructPermissionsParams({})
    )
  }
  getStudents(id){
    return this.auth.apiGet(
      '/public/test-ctrl/schools/students-teachers-classes',
      id,
      this.constructPermissionsParams({})
    )
  }


  // private utilities
}
