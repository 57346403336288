
export interface ICheckMarkMap {
  [key: string]: boolean;
}

export interface ICheckMarkMapping {
  [key: string]: ICheckMarkMap;
}

const checkMarkMap : ICheckMarkMap = {
  '1': true,
  '#': false
};

const brailleCheckMarkMap: ICheckMarkMap = {
  '1': true,
  '2': true,
  '3': true,
  '4': true,
  '#': false
}

export enum AssessmentCode {
  EQAO_G9 = 'EQAO_G9',
  EQAO_G10 = 'EQAO_G10',
  EQAO_G3 = 'EQAO_G3',
  EQAO_G6 = 'EQAO_G6'
}

export interface IDataMappingG9Student {
  source: string,
  target: string,
  sourceAlt?: string,
  exportIncl?: string[],
  importIncl?: string[],
  importReqIncl?: string[],
  key_namespace?: string,
  checkMarkMap?: ICheckMarkMap
}


export const DATA_MAPPING_EQAO_G9_STUDENT : IDataMappingG9Student[]= [
    { source: 'first_name', key_namespace:'eqao_sdc', target: 'first_name', sourceAlt:'FirstName', exportIncl:[AssessmentCode.EQAO_G9, AssessmentCode.EQAO_G10], importReqIncl:[AssessmentCode.EQAO_G9, AssessmentCode.EQAO_G10] },
    { source: 'last_name', key_namespace:'eqao_sdc', target: 'last_name', sourceAlt:'LastName', exportIncl:[AssessmentCode.EQAO_G9, AssessmentCode.EQAO_G10], importReqIncl:[AssessmentCode.EQAO_G9, AssessmentCode.EQAO_G10]},
    { source: 'id', key_namespace:'eqao_sdc', target: 'uid'},
    { source: 'roles', key_namespace:'eqao_sdc', target: 'roles'},
    { source: 'StudentOEN', key_namespace:'eqao_sdc', target: 'eqao_student_gov_id'},
    { source: 'Program', key_namespace:'eqao_sdc', target: 'eqao_g9_course'},
    { source: 'SASN', key_namespace:'eqao_sdc', target: 'SASN'},
    { source: 'IEP', key_namespace:'eqao_sdc', target: 'eqao_iep', checkMarkMap},
    { source: 'Linear', key_namespace:'eqao_sdc', target: 'eqao_dyn_linear', checkMarkMap},
    { source: 'IPRCBehaviour', key_namespace:'eqao_sdc', target: '_identification_placement_behaviour', checkMarkMap },
    { source: 'IPRCAutism', key_namespace:'eqao_sdc', target: '_identification_placement_autism', checkMarkMap },
    { source: 'IPRCDeaf', key_namespace:'eqao_sdc', target: '_identification_placement_deaf', checkMarkMap },
    { source: 'IPRCBlind', key_namespace:'eqao_sdc', target: '_identification_placement_blind', checkMarkMap },
    { source: 'IPRCGifted', key_namespace:'eqao_sdc', target: '_identification_placement_gifted', checkMarkMap },
    { source: 'IPRCIntellectual', key_namespace:'eqao_sdc', target: '_identification_placement_intellectual', checkMarkMap },
    { source: 'IPRCDevelopmental', key_namespace:'eqao_sdc', target: '_identification_placement_developmental', checkMarkMap },
    { source: 'IPRCMultiple', key_namespace:'eqao_sdc', target: '_identification_placement_multiple', checkMarkMap },
    { source: 'IPRCPhysical', key_namespace:'eqao_sdc', target: '_identification_placement_physical', checkMarkMap },
    { source: 'IPRCSpeech', key_namespace:'eqao_sdc', target: '_identification_placement_speech', checkMarkMap },
    { source: 'IPRCLanguage', key_namespace:'eqao_sdc', target: '_identification_placement_language', checkMarkMap },
    { source: 'IPRCLearning', key_namespace:'eqao_sdc', target: '_identification_placement_learning', checkMarkMap },
    { source: 'AccAssistiveTech', key_namespace:'eqao_sdc', target: 'eqao_acc_assistive_tech'},
    { source: 'AccAssistiveTech_Chrome', key_namespace:'eqao_sdc', target:'eqao_acc_assistive_tech_1_chrome', checkMarkMap},
    { source: 'AccAssistiveTech_1_Other', key_namespace:'eqao_sdc', target:'eqao_acc_assistive_tech_1_other', checkMarkMap},
    { source: 'AccAssistiveTech_Kurz_dl', key_namespace:'eqao_sdc', target:'eqao_acc_assistive_tech_2_kurz_dl', checkMarkMap},
    { source: 'AccAssistiveTech_Kurz_ext', key_namespace:'eqao_sdc', target:'eqao_acc_assistive_tech_2_kurz_ext', checkMarkMap},
    { source: 'AccAssistiveTech_Nvda', key_namespace:'eqao_sdc', target:'eqao_acc_assistive_tech_2_nvda', checkMarkMap},
    { source: 'AccAssistiveTech_Voiceover', key_namespace:'eqao_sdc', target:'eqao_acc_assistive_tech_2_voiceover', checkMarkMap},
    { source: 'AccAssistiveTech_Readaloud', key_namespace:'eqao_sdc', target:'eqao_acc_assistive_tech_2_readaloud', checkMarkMap},
    { source: 'AccAssistiveTech_Jaws', key_namespace:'eqao_sdc', target:'eqao_acc_assistive_tech_2_jaws', checkMarkMap},
    { source: 'AccAssistiveTech_Chromevox', key_namespace:'eqao_sdc', target:'eqao_acc_assistive_tech_2_chromevox', checkMarkMap},
    { source: 'AccAssistiveTech_Natread', key_namespace:'eqao_sdc', target:'eqao_acc_assistive_tech_2_read', checkMarkMap},
    { source: 'AccAssistiveTech_Other', key_namespace:'eqao_sdc', target:'eqao_acc_assistive_tech_2_other', checkMarkMap},
    { source: 'AccAssistiveTech_Custom', key_namespace:'eqao_sdc', target:'eqao_acc_assistive_tech_custom'},
    { source: 'AccAssistiveTech_Chrome_2', key_namespace:'eqao_sdc', target:'eqao_acc_assistive_tech_3_chrome_2', checkMarkMap},
    { source: 'AccBraille', key_namespace:'eqao_sdc', target: 'eqao_acc_braille'},
    { source: 'AccAudioVersion', key_namespace:'eqao_sdc', target: 'AccAudioVersion', checkMarkMap },
    { source: 'AccScribing', key_namespace:'eqao_sdc', target: 'eqao_acc_scribing', checkMarkMap},
    { source: 'StudentType', key_namespace:'eqao_sdc', target: 'eqao_student_school_enrol_type'},
    { source: 'DateofBirth', key_namespace:'eqao_sdc', target: 'date_of_birth'},
    { source: 'Gender', key_namespace:'eqao_sdc', target: 'eqao_gender'},
    { source: 'FrenchImmersionOrExtended', key_namespace:'eqao_sdc', target: 'eqao_g9_french', checkMarkMap},
    { source: 'IS_G3', target: 'eqao_is_g3', key_namespace:'eqao_sdc'},
    { source: 'IS_G6', target: 'eqao_is_g6', key_namespace:'eqao_sdc'},
    { source: 'IS_G9', target: 'eqao_is_g9', key_namespace:'eqao_sdc'},
    { source: 'IS_G10', target: 'eqao_is_g10', key_namespace:'eqao_sdc'},
    { source: 'StatusInCanada', key_namespace:'eqao_sdc', target: 'eqao_status_in_canada'},
    { source: 'OutOfProvinceResidence', key_namespace:'eqao_sdc', target: 'eqao_out_province_residence'},
    { source: 'EnrolledOntario', key_namespace:'eqao_sdc', target: 'eqao_enrolled_ontario'},
    { source: 'FirstLanguage', key_namespace:'eqao_sdc', target: 'eqao_first_language'/*, checkMarkMap*/},
    { source: 'IndigenousType', key_namespace:'eqao_sdc', target: 'eqao_indigenous_type'},
    { source: 'DateEnteredSchool', key_namespace:'eqao_sdc', target: 'eqao_date_entered_school'},
    { source: 'DateEnteredBoard', key_namespace:'eqao_sdc',target: 'eqao_date_entered_board'},
    { source: 'BornOutsideCanada', key_namespace:'eqao_sdc',target: 'eqao_born_outside_canada'},
    { source: 'TimeInCanada', key_namespace:'eqao_sdc',target: 'eqao_time_in_canada'},
    { source: 'Refugee', key_namespace:'eqao_sdc',target: 'eqao_refugee'},
    { source: 'AccLargePrint', key_namespace:'eqao_sdc',target: 'eqao_acc_large_print'},
    { source: 'AccColoured', key_namespace:'eqao_sdc', target: 'eqao_acc_coloured'}, //This is different from current setup. currently eqao_acc_coloured_blue, eqao_coloured_green, etc.
    { source: 'AccLPColoured', key_namespace:'eqao_sdc', target: 'eqao_acc_lp_coloured'}, //This is different from current setup. currently eqao_acc_lp_coloured_blue etc...
    { source: 'AccSign', key_namespace:'eqao_sdc', target: 'eqao_pres_format', checkMarkMap},
    { source: 'AccAssistiveDevices', key_namespace:'eqao_sdc',target: '_changes_in_setting', checkMarkMap},
    { source: 'AccBreaks', key_namespace:'eqao_sdc', target: '_extended_time', checkMarkMap},
    { source: 'AccAudioResponse', key_namespace:'eqao_sdc', target: '_audio_recording_of_resp', checkMarkMap},
    { source: 'AccAudioVersion', key_namespace:'eqao_sdc', target: 'AccAudioVersion', checkMarkMap},
    { source: 'AccComputer', key_namespace:'eqao_sdc', target: '_assist_tech_for_recording', checkMarkMap},
    { source: 'SpecPermTemp', key_namespace:'eqao_sdc',  target: '_no_iep_temp_inj', checkMarkMap},
    { source: 'SpecPermMoved', key_namespace:'eqao_sdc', target: '_no_iep_recent_arriv', checkMarkMap},
    { source: 'ESLELD', key_namespace:'eqao_sdc', target: '_ell', checkMarkMap},
    { source: 'ALFPANA', key_namespace:'eqao_sdc', target: 'ALFPANA'},
    { source: 'SpecProvBreaks', key_namespace:'eqao_sdc', target: '_periodic_breaks', checkMarkMap},
    { source: 'TeacherNotes', key_namespace:'eqao_sdc', target: 'teacher_notes'},
    { source: 'class_code', key_namespace:'eqao_sdc', target: 'eqao_g9_class_code'},
    { source: 'teacher', key_namespace:'eqao_sdc', target: 'teacher'},
    { source: 'SemesterIndicator', key_namespace:'eqao_sdc', target: 'SemesterIndicator' },
    { source: 'EligibilityStatus', key_namespace:'eqao_sdc', target: 'EligibilityStatus'}, 
    { source: 'LevelofStudyLanguage', key_namespace:'eqao_sdc', target: 'LevelofStudyLanguage'}, 
    { source: 'DateOfFTE', key_namespace:'eqao_sdc', target: 'DateOfFTE'}, 
    { source: 'Grouping', key_namespace:'eqao_sdc', target: 'Grouping'}, 
    { source: 'ClassCode', key_namespace:'eqao_sdc', target: 'ClassCode'}, 
    { source: 'Homeroom', key_namespace:'eqao_sdc', target: 'Homeroom'}, 
    { source: 'NonParticipationStatus', key_namespace:'eqao_sdc', target: 'NonParticipationStatus'},
    { source: 'NonParticipationStatus1', key_namespace:'eqao_sdc', target: 'NonParticipationStatus_exempted', checkMarkMap},
    { source: 'NonParticipationStatus2', key_namespace:'eqao_sdc', target: 'NonParticipationStatus_deferred', checkMarkMap},
    { source: 'NonParticipationStatus3', key_namespace:'eqao_sdc', target: 'NonParticipationStatus_osslc', checkMarkMap},  
    { source: 'Graduating', key_namespace:'eqao_sdc', target: 'Graduating'}, 
    { source: 'AccReadGraphic', key_namespace:'eqao_sdc', target: 'AccReadGraphic'}, 
    { source: 'AccReading', key_namespace:'eqao_sdc', target: 'AccReading'}, 
    { source: 'AccVideotapeResponse', key_namespace:'eqao_sdc', target: 'AccVideotapeResponse', checkMarkMap}, 
    { source: 'AccOther', key_namespace:'eqao_sdc', target: 'AccOther', checkMarkMap}, 
    { source: 'SpecPermIEP', key_namespace:'eqao_sdc', target: 'SpecPermIEP', checkMarkMap}, 
    { source: 'osslt__is_submitted', key_namespace:'eqao_sdc', target: 'osslt__is_submitted'}, 
    { source: 'osslt__is_success', key_namespace:'eqao_sdc', target: 'osslt__is_success'},
    { source: 'errMsg', key_namespace:'eqao_sdc', target: 'errMsg'},
    { source: 'MathClassWhen', key_namespace:'eqao_sdc', target: 'eqao_math_class_when'},
    { source: 'LearningFormat', key_namespace:'eqao_sdc', target: 'eqao_learning_format'},
    { source: 'semester', key_namespace:'eqao_sdc', target:'semester'},
    { source: 'PaperTest', key_namespace:'eqao_sdc', target: 'PaperTest' },
    { source: 'CreatedDate', key_namespace:'eqao_sdc', target: 'CreatedDate' },
    { source: 'SchMident', key_namespace:'eqao_sdc', target: 'SchMident' },
    { source: 'BrdMident', key_namespace:'eqao_sdc', target: 'BrdMident' },
    { source: 'ProjectID', key_namespace:'eqao_sdc', target: 'ProjectID' },
    { source: 'StudentUID', key_namespace:'eqao_sdc', target: 'StudentUID' },
    { source: 'MathTeacherFirstName', key_namespace:'eqao_sdc', target: 'MathTeacherFirstName' },
    { source: 'MathTeacherLastName', key_namespace:'eqao_sdc', target: 'MathTeacherLastName' },

    { source: 'ALFPANA', key_namespace:'eqao_sdc_g10', target: 'ALFPANA'},
    { source: 'AccAssistiveDevices', key_namespace:'eqao_sdc_g10',target: '_changes_in_setting', checkMarkMap},
    { source: 'AccAssistiveTech', key_namespace:'eqao_sdc_g10', target: 'eqao_acc_assistive_tech'},
    { source: 'AccAssistiveTech_Chrome', key_namespace:'eqao_sdc_g10', target:'eqao_acc_assistive_tech_1_chrome', checkMarkMap},
    { source: 'AccAssistiveTech_1_Other', key_namespace:'eqao_sdc_g10', target:'eqao_acc_assistive_tech_1_other', checkMarkMap},
    { source: 'AccAssistiveTech_Kurz_dl', key_namespace:'eqao_sdc_g10', target:'eqao_acc_assistive_tech_2_kurz_dl', checkMarkMap},
    { source: 'AccAssistiveTech_Kurz_ext', key_namespace:'eqao_sdc_g10', target:'eqao_acc_assistive_tech_2_kurz_ext', checkMarkMap},
    { source: 'AccAssistiveTech_Nvda', key_namespace:'eqao_sdc_g10', target:'eqao_acc_assistive_tech_2_nvda', checkMarkMap},
    { source: 'AccAssistiveTech_Voiceover', key_namespace:'eqao_sdc_g10', target:'eqao_acc_assistive_tech_2_voiceover', checkMarkMap},
    { source: 'AccAssistiveTech_Readaloud', key_namespace:'eqao_sdc_g10', target:'eqao_acc_assistive_tech_2_readaloud', checkMarkMap},
    { source: 'AccAssistiveTech_Jaws', key_namespace:'eqao_sdc_g10', target:'eqao_acc_assistive_tech_2_jaws', checkMarkMap},
    { source: 'AccAssistiveTech_Chromevox', key_namespace:'eqao_sdc_g10', target:'eqao_acc_assistive_tech_2_chromevox', checkMarkMap},
    { source: 'AccAssistiveTech_Natread', key_namespace:'eqao_sdc_g10', target:'eqao_acc_assistive_tech_2_read', checkMarkMap},
    { source: 'AccAssistiveTech_Other', key_namespace:'eqao_sdc_g10', target:'eqao_acc_assistive_tech_2_other', checkMarkMap},
    { source: 'AccAssistiveTech_Custom', key_namespace:'eqao_sdc_g10', target:'eqao_acc_assistive_tech_custom'},
    { source: 'AccAssistiveTech_Chrome_2', key_namespace:'eqao_sdc_g10', target:'eqao_acc_assistive_tech_3_chrome_2', checkMarkMap},
    { source: 'AccAudioResponse', key_namespace:'eqao_sdc_g10', target: '_audio_recording_of_resp', checkMarkMap},
    { source: 'AccAudioVersion', key_namespace:'eqao_sdc_g10', target: 'AccAudioVersion', checkMarkMap },
    { source: 'AccBraille', key_namespace:'eqao_sdc_g10', target: 'eqao_acc_braille'},
    { source: 'AccBreaks', key_namespace:'eqao_sdc_g10', target: '_extended_time', checkMarkMap},
    { source: 'AccColoured', key_namespace:'eqao_sdc_g10', target: 'eqao_acc_coloured'},
    { source: 'AccComputer', key_namespace:'eqao_sdc_g10', target: '_assist_tech_for_recording', checkMarkMap},
    { source: 'AccLPColoured', key_namespace:'eqao_sdc_g10', target: 'eqao_acc_lp_coloured'},
    { source: 'AccLargePrint', key_namespace:'eqao_sdc_g10',target: 'eqao_acc_large_print'},
    { source: 'AccOther', key_namespace:'eqao_sdc_g10', target: 'AccOther', checkMarkMap}, 
    { source: 'AccReadGraphic', key_namespace:'eqao_sdc_g10', target: 'AccReadGraphic'},
    { source: 'AccReading', key_namespace:'eqao_sdc_g10', target: 'AccReading'}, 
    { source: 'AccScribing', key_namespace:'eqao_sdc_g10', target: 'eqao_acc_scribing', checkMarkMap},
    { source: 'AccSign', key_namespace:'eqao_sdc_g10', target: 'eqao_pres_format', checkMarkMap},
    { source: 'AccVideotapeResponse', key_namespace:'eqao_sdc_g10', target: 'AccVideotapeResponse', checkMarkMap},
    { source: 'BrdMident', key_namespace:'eqao_sdc_g10', target: 'BrdMident' },
    { source: 'CreatedDate', key_namespace:'eqao_sdc_g10', target: 'CreatedDate' },
    { source: 'DateOfFTE', key_namespace:'eqao_sdc_g10', target: 'DateOfFTE'},
    { source: 'ESLELD', key_namespace:'eqao_sdc_g10', target: '_ell', checkMarkMap},
    { source: 'EligibilityStatus', key_namespace:'eqao_sdc_g10', target: 'EligibilityStatus'}, 
    { source: 'Graduating', key_namespace:'eqao_sdc_g10', target: 'Graduating'},
    { source: 'Grouping', key_namespace:'eqao_sdc_g10', target: 'Grouping'}, 
    { source: 'Homeroom', key_namespace:'eqao_sdc_g10', target: 'Homeroom'},
    { source: 'IEP', key_namespace:'eqao_sdc_g10', target: 'eqao_iep', checkMarkMap},
    { source: 'IPRCAutism', key_namespace:'eqao_sdc_g10', target: '_identification_placement_autism', checkMarkMap },
    { source: 'IPRCBehaviour', key_namespace:'eqao_sdc_g10', target: '_identification_placement_behaviour', checkMarkMap },
    { source: 'IPRCBlind', key_namespace:'eqao_sdc_g10', target: '_identification_placement_blind', checkMarkMap },
    { source: 'IPRCDeaf', key_namespace:'eqao_sdc_g10', target: '_identification_placement_deaf', checkMarkMap },
    { source: 'IPRCDevelopmental', key_namespace:'eqao_sdc_g10', target: '_identification_placement_developmental', checkMarkMap },
    { source: 'IPRCGifted', key_namespace:'eqao_sdc_g10', target: '_identification_placement_gifted', checkMarkMap },
    { source: 'IPRCIntellectual', key_namespace:'eqao_sdc_g10', target: '_identification_placement_intellectual', checkMarkMap },
    { source: 'IPRCLanguage', key_namespace:'eqao_sdc_g10', target: '_identification_placement_language', checkMarkMap },
    { source: 'IPRCMultiple', key_namespace:'eqao_sdc_g10', target: '_identification_placement_multiple', checkMarkMap },
    { source: 'IPRCPhysical', key_namespace:'eqao_sdc_g10', target: '_identification_placement_physical', checkMarkMap },
    { source: 'IPRCSpeech', key_namespace:'eqao_sdc_g10', target: '_identification_placement_speech', checkMarkMap },
    { source: 'IPRCLearning', key_namespace:'eqao_sdc_g10', target: '_identification_placement_learning', checkMarkMap },
    { source: 'LearningFormat', key_namespace:'eqao_sdc_g10', target: 'eqao_learning_format'},
    { source: 'LevelofStudyLanguage', key_namespace:'eqao_sdc_g10', target: 'LevelofStudyLanguage'},
    { source: 'NonParticipationStatus', key_namespace:'eqao_sdc_g10', target: 'NonParticipationStatus'},
    { source: 'NonParticipationStatus1', key_namespace:'eqao_sdc_g10', target: 'NonParticipationStatus_exempted', checkMarkMap},
    { source: 'NonParticipationStatus2', key_namespace:'eqao_sdc_g10', target: 'NonParticipationStatus_deferred', checkMarkMap},
    { source: 'NonParticipationStatus3', key_namespace:'eqao_sdc_g10', target: 'NonParticipationStatus_osslc', checkMarkMap},
    { source: 'PaperTest', key_namespace:'eqao_sdc_g10', target: 'PaperTest' },
    { source: 'ProjectID', key_namespace:'eqao_sdc_g10', target: 'ProjectID' },
    { source: 'SchMident', key_namespace:'eqao_sdc_g10', target: 'SchMident' },
    { source: 'SemesterIndicator', key_namespace:'eqao_sdc_g10', target: 'SemesterIndicator' },
    { source: 'SpecPermIEP', key_namespace:'eqao_sdc_g10', target: 'SpecPermIEP', checkMarkMap},
    { source: 'SpecPermMoved', key_namespace:'eqao_sdc_g10', target: '_no_iep_recent_arriv', checkMarkMap},
    { source: 'SpecPermTemp', key_namespace:'eqao_sdc_g10',  target: '_no_iep_temp_inj', checkMarkMap},
    { source: 'SpecProvBreaks', key_namespace:'eqao_sdc_g10', target: '_periodic_breaks', checkMarkMap},
    { source: 'StudentUID', key_namespace:'eqao_sdc_g10', target: 'StudentUID' },
    { source: 'class_code', key_namespace:'eqao_sdc_g10', target: 'eqao_g9_class_code'},
    { source: 'Linear', key_namespace:'eqao_sdc_g10', target: 'eqao_dyn_linear', checkMarkMap},
    { source: 'errMsg', key_namespace:'eqao_sdc_g10', target: 'errMsg'},
   
    { source: 'FrenchImmersion', key_namespace:'eqao_sdc_g3', target: 'eqao_pj_french', checkMarkMap},
    { source: 'IsCrScanDefault', key_namespace: 'eqao_sdc_g3', target: 'IsCrScanDefault', checkMarkMap},
    { source: 'IsLargePrint', key_namespace: 'eqao_sdc_g3', target: 'IsLargePrint', checkMarkMap},
    { source: 'Grade', key_namespace: 'eqao_sdc_g3', target: 'eqao_grade'},
    { source: 'ALFPANA', key_namespace: 'eqao_sdc_g3', target: 'ALFPANA'},
    { source: 'ESLELD', key_namespace:'eqao_sdc_g3', target: '_ell', checkMarkMap},
    { source: 'LearningFormat', key_namespace:'eqao_sdc_g3', target: 'eqao_learning_format'},
    { source: 'JrKindergarten', key_namespace:'eqao_sdc_g3', target: 'eqao_full_day_junior'},
    { source: 'SrKindergarten', key_namespace:'eqao_sdc_g3', target: 'eqao_full_day_senior'},
    { source: 'IEP', key_namespace:'eqao_sdc_g3', target: 'eqao_iep', checkMarkMap},
    { source: 'IPRCAutism', key_namespace:'eqao_sdc_g3', target: '_identification_placement_autism', checkMarkMap },
    { source: 'IPRCBehaviour', key_namespace:'eqao_sdc_g3', target: '_identification_placement_behaviour', checkMarkMap },
    { source: 'IPRCBlind', key_namespace:'eqao_sdc_g3', target: '_identification_placement_blind', checkMarkMap },
    { source: 'IPRCDeaf', key_namespace:'eqao_sdc_g3', target: '_identification_placement_deaf', checkMarkMap },
    { source: 'IPRCDevelopmental', key_namespace:'eqao_sdc_g3', target: '_identification_placement_developmental', checkMarkMap },
    { source: 'IPRCGifted', key_namespace:'eqao_sdc_g3', target: '_identification_placement_gifted', checkMarkMap },
    { source: 'IPRCIntellectual', key_namespace:'eqao_sdc_g3', target: '_identification_placement_intellectual', checkMarkMap },
    { source: 'IPRCLanguage', key_namespace:'eqao_sdc_g3', target: '_identification_placement_language', checkMarkMap },
    { source: 'IPRCMultiple', key_namespace:'eqao_sdc_g3', target: '_identification_placement_multiple', checkMarkMap },
    { source: 'IPRCPhysical', key_namespace:'eqao_sdc_g3', target: '_identification_placement_physical', checkMarkMap },
    { source: 'IPRCSpeech', key_namespace:'eqao_sdc_g3', target: '_identification_placement_speech', checkMarkMap },
    { source: 'IPRCLearning', key_namespace:'eqao_sdc_g3', target: '_identification_placement_learning', checkMarkMap },
    { source: 'AccAssistiveTechRead', key_namespace:'eqao_sdc_g3', target: 'eqao_acc_assistive_tech_pj_reading'},
    { source: 'AccAssistiveTech_Chrome_Reading', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_1_pj_reading_chrome', checkMarkMap},
    { source: 'AccAssistiveTech_1_Reading_Other', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_1_pj_reading_other', checkMarkMap},
    { source: 'AccAssistiveTech_Reading_Kurz_dl', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_reading_kurz_dl', checkMarkMap},
    { source: 'AccAssistiveTech_Reading_Kurz_ext', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_reading_kurz_ext', checkMarkMap},
    { source: 'AccAssistiveTech_Reading_Nvda', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_reading_nvda', checkMarkMap},
    { source: 'AccAssistiveTech_Reading_Voiceover', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_reading_voiceover', checkMarkMap},
    { source: 'AccAssistiveTech_Reading_Readaloud', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_reading_readaloud', checkMarkMap},
    { source: 'AccAssistiveTech_Reading_Jaws', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_reading_jaws', checkMarkMap},
    { source: 'AccAssistiveTech_Reading_Chromevox', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_reading_chromevox', checkMarkMap},
    { source: 'AccAssistiveTech_Reading_Natread', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_reading_read', checkMarkMap},
    { source: 'AccAssistiveTech_Reading_Other', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_reading_other', checkMarkMap},
    { source: 'AccAssistiveTech_Reading_Custom', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_custom_pj_reading'},
    { source: 'AccAssistiveTechWrite', key_namespace:'eqao_sdc_g3', target: 'eqao_acc_assistive_tech_pj_writing'},
    { source: 'AccAssistiveTech_Chrome_Writing', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_1_pj_writing_chrome', checkMarkMap},
    { source: 'AccAssistiveTech_1_Writing_Other', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_1_pj_writing_other', checkMarkMap},
    { source: 'AccAssistiveTech_Writing_Kurz_dl', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_writing_kurz_dl', checkMarkMap},
    { source: 'AccAssistiveTech_Writing_Kurz_ext', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_writing_kurz_ext', checkMarkMap},
    { source: 'AccAssistiveTech_Writing_Nvda', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_writing_nvda', checkMarkMap},
    { source: 'AccAssistiveTech_Writing_Voiceover', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_writing_voiceover', checkMarkMap},
    { source: 'AccAssistiveTech_Writing_Readaloud', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_writing_readaloud', checkMarkMap},
    { source: 'AccAssistiveTech_Writing_Jaws', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_writing_jaws', checkMarkMap},
    { source: 'AccAssistiveTech_Writing_Chromevox', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_writing_chromevox', checkMarkMap},
    { source: 'AccAssistiveTech_Writing_Natread', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_writing_read', checkMarkMap},
    { source: 'AccAssistiveTech_Writing_Other', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_writing_other', checkMarkMap},
    { source: 'AccAssistiveTech_Writing_Custom', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_custom_pj_writing'},
    { source: 'AccAssistiveTechMath', key_namespace:'eqao_sdc_g3', target: 'eqao_acc_assistive_tech_pj_mathematics'},
    { source: 'AccAssistiveTech_Chrome_Mathematics', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_1_pj_mathematics_chrome', checkMarkMap},
    { source: 'AccAssistiveTech_1_Mathematics_Other', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_1_pj_mathematics_other', checkMarkMap},
    { source: 'AccAssistiveTech_Mathematics_Kurz_dl', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_mathematics_kurz_dl', checkMarkMap},
    { source: 'AccAssistiveTech_Mathematics_Kurz_ext', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_mathematics_kurz_ext', checkMarkMap},
    { source: 'AccAssistiveTech_Mathematics_Nvda', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_mathematics_nvda', checkMarkMap},
    { source: 'AccAssistiveTech_Mathematics_Voiceover', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_mathematics_voiceover', checkMarkMap},
    { source: 'AccAssistiveTech_Mathematics_Readaloud', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_mathematics_readaloud', checkMarkMap},
    { source: 'AccAssistiveTech_Mathematics_Jaws', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_mathematics_jaws', checkMarkMap},
    { source: 'AccAssistiveTech_Mathematics_Chromevox', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_mathematics_chromevox', checkMarkMap},
    { source: 'AccAssistiveTech_Mathematics_Natread', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_mathematics_read', checkMarkMap},
    { source: 'AccAssistiveTech_Mathematics_Other', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_2_pj_mathematics_other', checkMarkMap},
    { source: 'AccAssistiveTech_Mathematics_Custom', key_namespace:'eqao_sdc_g3', target:'eqao_acc_assistive_tech_custom_pj_mathematics'},
    { source: 'AccBrailleRead', key_namespace:'eqao_sdc_g3', target: 'eqao_acc_braille_pj_reading'},
    { source: 'AccAudioVersionRead', key_namespace:'eqao_sdc_g3', target: 'AccAudioVersion_pj_reading', checkMarkMap },
    { source: 'AlternateFormRead', key_namespace:'eqao_sdc_g3', target: 'eqao_alternate_form_pj_reading', checkMarkMap },
    { source: 'AccSignRead', key_namespace:'eqao_sdc_g3', target: 'eqao_pres_format_pj_reading', checkMarkMap},
    { source: 'AccScribingRead', key_namespace:'eqao_sdc_g3', target: 'eqao_acc_scribing_pj_reading', checkMarkMap},
    { source: 'AccBrailleWrite', key_namespace:'eqao_sdc_g3', target: 'eqao_acc_braille_pj_writing'},
    { source: 'AccAudioVersionWrite', key_namespace:'eqao_sdc_g3', target: 'AccAudioVersion_pj_writing', checkMarkMap },
    { source: 'AlternateFormWrite', key_namespace:'eqao_sdc_g3', target: 'eqao_alternate_form_pj_writing', checkMarkMap },
    { source: 'AccSignWrite', key_namespace:'eqao_sdc_g3', target: 'eqao_pres_format_pj_writing', checkMarkMap},
    { source: 'AccScribingWrite', key_namespace:'eqao_sdc_g3', target: 'eqao_acc_scribing_pj_writing', checkMarkMap},
    { source: 'AccBrailleMath', key_namespace:'eqao_sdc_g3', target: 'eqao_acc_braille_pj_mathematics'},
    { source: 'AccAudioVersionMath', key_namespace:'eqao_sdc_g3', target: 'AccAudioVersion_pj_mathematics'},
    { source: 'AlternateFormMath', key_namespace:'eqao_sdc_g3', target: 'eqao_alternate_form_pj_mathematics'},
    { source: 'AccSignMath', key_namespace:'eqao_sdc_g3', target: 'eqao_pres_format_pj_mathematics', checkMarkMap},
    { source: 'AccScribingMath', key_namespace:'eqao_sdc_g3', target: 'eqao_acc_scribing_pj_mathematics', checkMarkMap},
    { source: 'ExemptionRead', key_namespace:'eqao_sdc_g3', target: 'eqao_exemption_pj_reading', checkMarkMap},
    { source: 'ExemptionWrite', key_namespace:'eqao_sdc_g3', target: 'eqao_exemption_pj_writing', checkMarkMap},
    { source: 'ExemptionMath', key_namespace:'eqao_sdc_g3', target: 'eqao_exemption_pj_mathematics', checkMarkMap},
    { source: 'SpecPermTemp', key_namespace:'eqao_sdc_g3',  target: '_no_iep_temp_inj', checkMarkMap},
    { source: 'SpecPermMoved', key_namespace:'eqao_sdc_g3', target: '_no_iep_recent_arriv', checkMarkMap},
    { source: 'class_code', key_namespace:'eqao_sdc_g3', target: 'eqao_g9_class_code'},
    { source: 'LearningFormat', key_namespace:'eqao_sdc_g3', target: 'eqao_learning_format'},
    { source: 'Linear', key_namespace:'eqao_sdc_g3', target: 'eqao_dyn_linear', checkMarkMap},
    { source: 'errMsg', key_namespace:'eqao_sdc_g3', target: 'errMsg'},
    { source: 'SpecEdNoExpectationReadWrite', key_namespace:'eqao_sdc_g3', target: 'eqao_spec_no_exception_pj_readingwriting'},
    { source: 'SpecEdNoExpectationMath', key_namespace:'eqao_sdc_g3', target: 'eqao_spec_no_exception_pj_mathematics'},
    { source: 'ClassTeacherFirstName', key_namespace:'eqao_sdc_g3', target: 'ClassTeacherFirstName'},
    { source: 'ClassTeacherLastName', key_namespace:'eqao_sdc_g3', target: 'ClassTeacherLastName'},

    { source: 'FrenchImmersion', key_namespace:'eqao_sdc_g6', target: 'eqao_pj_french', checkMarkMap},
    { source: 'IsCrScanDefault', key_namespace: 'eqao_sdc_g6', target: 'IsCrScanDefault', checkMarkMap},
    { source: 'IsLargePrint', key_namespace: 'eqao_sdc_g6', target: 'IsLargePrint', checkMarkMap},
    { source: 'Grade', key_namespace: 'eqao_sdc_g6', target: 'eqao_grade'},
    { source: 'ALFPANA', key_namespace: 'eqao_sdc_g6', target: 'ALFPANA'},
    { source: 'ESLELD', key_namespace:'eqao_sdc_g6', target: '_ell', checkMarkMap},
    { source: 'LearningFormat', key_namespace:'eqao_sdc_g6', target: 'eqao_learning_format'},
    { source: 'JrKindergarten', key_namespace:'eqao_sdc_g6', target: 'eqao_full_day_junior'},
    { source: 'SrKindergarten', key_namespace:'eqao_sdc_g6', target: 'eqao_full_day_senior'},
    { source: 'IEP', key_namespace:'eqao_sdc_g6', target: 'eqao_iep', checkMarkMap},
    { source: 'IPRCAutism', key_namespace:'eqao_sdc_g6', target: '_identification_placement_autism', checkMarkMap },
    { source: 'IPRCBehaviour', key_namespace:'eqao_sdc_g6', target: '_identification_placement_behaviour', checkMarkMap },
    { source: 'IPRCBlind', key_namespace:'eqao_sdc_g6', target: '_identification_placement_blind', checkMarkMap },
    { source: 'IPRCDeaf', key_namespace:'eqao_sdc_g6', target: '_identification_placement_deaf', checkMarkMap },
    { source: 'IPRCDevelopmental', key_namespace:'eqao_sdc_g6', target: '_identification_placement_developmental', checkMarkMap },
    { source: 'IPRCGifted', key_namespace:'eqao_sdc_g6', target: '_identification_placement_gifted', checkMarkMap },
    { source: 'IPRCIntellectual', key_namespace:'eqao_sdc_g6', target: '_identification_placement_intellectual', checkMarkMap },
    { source: 'IPRCLanguage', key_namespace:'eqao_sdc_g6', target: '_identification_placement_language', checkMarkMap },
    { source: 'IPRCMultiple', key_namespace:'eqao_sdc_g6', target: '_identification_placement_multiple', checkMarkMap },
    { source: 'IPRCPhysical', key_namespace:'eqao_sdc_g6', target: '_identification_placement_physical', checkMarkMap },
    { source: 'IPRCSpeech', key_namespace:'eqao_sdc_g6', target: '_identification_placement_speech', checkMarkMap },
    { source: 'IPRCLearning', key_namespace:'eqao_sdc_g6', target: '_identification_placement_learning', checkMarkMap },
    { source: 'AccAssistiveTechRead', key_namespace:'eqao_sdc_g6', target: 'eqao_acc_assistive_tech_pj_reading'},
    { source: 'AccAssistiveTech_Chrome_Reading', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_1_pj_reading_chrome', checkMarkMap},
    { source: 'AccAssistiveTech_1_Reading_Other', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_1_pj_reading_other', checkMarkMap},
    { source: 'AccAssistiveTech_Reading_Kurz_dl', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_reading_kurz_dl', checkMarkMap},
    { source: 'AccAssistiveTech_Reading_Kurz_ext', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_reading_kurz_ext', checkMarkMap},
    { source: 'AccAssistiveTech_Reading_Nvda', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_reading_nvda', checkMarkMap},
    { source: 'AccAssistiveTech_Reading_Voiceover', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_reading_voiceover', checkMarkMap},
    { source: 'AccAssistiveTech_Reading_Readaloud', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_reading_readaloud', checkMarkMap},
    { source: 'AccAssistiveTech_Reading_Jaws', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_reading_jaws', checkMarkMap},
    { source: 'AccAssistiveTech_Reading_Chromevox', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_reading_chromevox', checkMarkMap},
    { source: 'AccAssistiveTech_Reading_Natread', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_reading_read', checkMarkMap},
    { source: 'AccAssistiveTech_Reading_Other', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_reading_other', checkMarkMap},
    { source: 'AccAssistiveTech_Reading_Custom', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_custom_pj_reading'},
    { source: 'AccAssistiveTechWrite', key_namespace:'eqao_sdc_g6', target: 'eqao_acc_assistive_tech_pj_writing'},
    { source: 'AccAssistiveTech_Chrome_Writing', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_1_pj_writing_chrome', checkMarkMap},
    { source: 'AccAssistiveTech_1_Writing_Other', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_1_pj_writing_other', checkMarkMap},
    { source: 'AccAssistiveTech_Writing_Kurz_dl', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_writing_kurz_dl', checkMarkMap},
    { source: 'AccAssistiveTech_Writing_Kurz_ext', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_writing_kurz_ext', checkMarkMap},
    { source: 'AccAssistiveTech_Writing_Nvda', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_writing_nvda', checkMarkMap},
    { source: 'AccAssistiveTech_Writing_Voiceover', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_writing_voiceover', checkMarkMap},
    { source: 'AccAssistiveTech_Writing_Readaloud', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_writing_readaloud', checkMarkMap},
    { source: 'AccAssistiveTech_Writing_Jaws', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_writing_jaws', checkMarkMap},
    { source: 'AccAssistiveTech_Writing_Chromevox', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_writing_chromevox', checkMarkMap},
    { source: 'AccAssistiveTech_Writing_Natread', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_writing_read', checkMarkMap},
    { source: 'AccAssistiveTech_Writing_Other', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_writing_other', checkMarkMap},
    { source: 'AccAssistiveTech_Writing_Custom', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_custom_pj_writing'},
    { source: 'AccAssistiveTechMath', key_namespace:'eqao_sdc_g6', target: 'eqao_acc_assistive_tech_pj_mathematics'},
    { source: 'AccAssistiveTech_Chrome_Mathematics', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_1_pj_mathematics_chrome', checkMarkMap},
    { source: 'AccAssistiveTech_1_Mathematics_Other', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_1_pj_mathematics_other', checkMarkMap},
    { source: 'AccAssistiveTech_Mathematics_Kurz_dl', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_mathematics_kurz_dl', checkMarkMap},
    { source: 'AccAssistiveTech_Mathematics_Kurz_ext', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_mathematics_kurz_ext', checkMarkMap},
    { source: 'AccAssistiveTech_Mathematics_Nvda', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_mathematics_nvda', checkMarkMap},
    { source: 'AccAssistiveTech_Mathematics_Voiceover', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_mathematics_voiceover', checkMarkMap},
    { source: 'AccAssistiveTech_Mathematics_Readaloud', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_mathematics_readaloud', checkMarkMap},
    { source: 'AccAssistiveTech_Mathematics_Jaws', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_mathematics_jaws', checkMarkMap},
    { source: 'AccAssistiveTech_Mathematics_Chromevox', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_mathematics_chromevox', checkMarkMap},
    { source: 'AccAssistiveTech_Mathematics_Natread', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_mathematics_read', checkMarkMap},
    { source: 'AccAssistiveTech_Mathematics_Other', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_2_pj_mathematics_other', checkMarkMap},
    { source: 'AccAssistiveTech_Mathematics_Custom', key_namespace:'eqao_sdc_g6', target:'eqao_acc_assistive_tech_custom_pj_mathematics'},
    { source: 'AccBrailleRead', key_namespace:'eqao_sdc_g6', target: 'eqao_acc_braille_pj_reading'},
    { source: 'AccAudioVersionRead', key_namespace:'eqao_sdc_g6', target: 'AccAudioVersion_pj_reading', checkMarkMap },
    { source: 'AlternateFormRead', key_namespace:'eqao_sdc_g6', target: 'eqao_alternate_form_pj_reading', checkMarkMap },
    { source: 'AccSignRead', key_namespace:'eqao_sdc_g6', target: 'eqao_pres_format_pj_reading', checkMarkMap},
    { source: 'AccScribingRead', key_namespace:'eqao_sdc_g6', target: 'eqao_acc_scribing_pj_reading', checkMarkMap},
    { source: 'AccBrailleWrite', key_namespace:'eqao_sdc_g6', target: 'eqao_acc_braille_pj_writing'},
    { source: 'AccAudioVersionWrite', key_namespace:'eqao_sdc_g6', target: 'AccAudioVersion_pj_writing', checkMarkMap },
    { source: 'AlternateFormWrite', key_namespace:'eqao_sdc_g6', target: 'eqao_alternate_form_pj_writing', checkMarkMap },
    { source: 'AccSignWrite', key_namespace:'eqao_sdc_g6', target: 'eqao_pres_format_pj_writing', checkMarkMap},
    { source: 'AccScribingWrite', key_namespace:'eqao_sdc_g6', target: 'eqao_acc_scribing_pj_writing', checkMarkMap},
    { source: 'AccBrailleMath', key_namespace:'eqao_sdc_g6', target: 'eqao_acc_braille_pj_mathematics'},
    { source: 'AccAudioVersionMath', key_namespace:'eqao_sdc_g6', target: 'AccAudioVersion_pj_mathematics' },
    { source: 'AlternateFormMath', key_namespace:'eqao_sdc_g6', target: 'eqao_alternate_form_pj_mathematics'},
    { source: 'AccSignMath', key_namespace:'eqao_sdc_g6', target: 'eqao_pres_format_pj_mathematics', checkMarkMap},
    { source: 'AccScribingMath', key_namespace:'eqao_sdc_g6', target: 'eqao_acc_scribing_pj_mathematics', checkMarkMap},
    { source: 'ExemptionRead', key_namespace:'eqao_sdc_g6', target: 'eqao_exemption_pj_reading', checkMarkMap},
    { source: 'ExemptionWrite', key_namespace:'eqao_sdc_g6', target: 'eqao_exemption_pj_writing', checkMarkMap},
    { source: 'ExemptionMath', key_namespace:'eqao_sdc_g6', target: 'eqao_exemption_pj_mathematics', checkMarkMap},
    { source: 'SpecPermTemp', key_namespace:'eqao_sdc_g6',  target: '_no_iep_temp_inj', checkMarkMap},
    { source: 'SpecPermMoved', key_namespace:'eqao_sdc_g6', target: '_no_iep_recent_arriv', checkMarkMap},
    { source: 'class_code', key_namespace:'eqao_sdc_g6', target: 'eqao_g9_class_code'},
    { source: 'LearningFormat', key_namespace:'eqao_sdc_g6', target: 'eqao_learning_format'},
    { source: 'Linear', key_namespace:'eqao_sdc_g6', target: 'eqao_dyn_linear', checkMarkMap},
    { source: 'errMsg', key_namespace:'eqao_sdc_g6', target: 'errMsg'},
    { source: 'SpecEdNoExpectationReadWrite', key_namespace:'eqao_sdc_g6', target: 'eqao_spec_no_exception_pj_readingwriting'},
    { source: 'SpecEdNoExpectationMath', key_namespace:'eqao_sdc_g6', target: 'eqao_spec_no_exception_pj_mathematics'},
    { source: 'ClassTeacherFirstName', key_namespace:'eqao_sdc_g6', target: 'ClassTeacherFirstName'},
    { source: 'ClassTeacherLastName', key_namespace:'eqao_sdc_g6', target: 'ClassTeacherLastName'},
  ];

  
