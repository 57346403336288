<div class="page-body">
  <div>
    <header
    [breadcrumbPath]="breadcrumb"
    ></header>
    <div class="page-content is-fullpage">

      <div  class="dashboard-cards-container" [class.is-full-width]="isFullWidth">

        <div>
          <tra slug="lbl_other_dashboards"></tra>
            <a routerLink="/{{currentLang()}}/test-auth/dashboard">Assessment Authoring</a>
          | <a routerLink="/{{currentLang()}}/scor-lead/dashboard">Scoring Leader</a>
        </div>

        <hr/>

        <div *ngIf="!this.isLoaded && !this.isLoadFailed">
          <!-- Loading -->
        </div>
        <div *ngIf="this.isLoadFailed">
          You do not have the required roles to access this page.
        </div>



        <div >
          <!-- *ngSwitchCase="TCView.EQAO_G9" -->
          <div >

            <div class="notification is-warning" *ngIf="IS_COUNTS_WARNING">
              This page is under maintenance and should not be used for reporting purposes.
            </div>
            <div class="notification is-danger" *ngIf="IS_COUNTS_DISABLED || IS_COUNTS_DISABLED">
              Some functionality on this page has been disabled while it undergoes maintenance.
            </div>

            <div class="card dashboard-card" *ngIf="!IS_COUNTS_DISABLED">
              <div class="image-holder">
                <img src="https://mathproficiencytest.ca/img/test-window-icon.png">
              </div>
              <div class="table-holder">
                <div class="lead">Administration Windows</div>
                <div class="space-between">
                  <div>Select an administration window to access further details</div>
                  <br>
                  <div>
                    <mat-slide-toggle [(ngModel)]="isShowQa" >
                      Show QA? ({{isShowQa ? 'Yes' : 'No'}})
                    </mat-slide-toggle>

                    <br>

                    <mat-slide-toggle [(ngModel)]="isShowBg" >
                      Show BG? ({{isShowBg ? 'Yes' : 'No'}})
                    </mat-slide-toggle>
                  </div>
                </div>
                <table>
                  <tr>
                    <th>ID</th>
                    <th>Window Title</th>
                    <th>Dates</th>
                    <!-- <th>Status</th> -->
                    <th *ngIf="!IS_TW_SELECT_DISABLED">Select</th>
                  </tr>
                  <ng-container *ngFor="let window of testWindows"> 
                    <tr *ngIf="showTestWindow(window)" >
                      <td>{{window.id}}</td>
                      <td>
                        <div>
                          <strong>{{renderTwTitle(window.title)}}</strong>
                        </div>
                        <div class="space-between">
                          <div>
                            <code>{{window.type_slug}}</code>
                            <code style="color: blue">{{window.test_ctrl_group_id}}</code>
                            <span style="margin-left: 0.5em;" *ngIf="window.is_sample==1" class="tag is-info">Sample</span>
                            <span style="margin-left: 0.5em;" *ngIf="window.is_qa==1" class="tag is-warning">QA</span>
                            <span style="margin-left: 0.5em;" *ngIf="window.is_bg==1" class="tag is-danger">BG</span>
                          </div>
                          <div style="font-size: 1.0em;">
                            <a class="summary-link" [routerLink]="getTestWindowRoute(window)">
                              Edit
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>{{renderDate(window.date_start)}} to {{renderDate(window.date_end)}}</td>
                      <!-- <td>{{window.status}}</td> -->
                      <td  *ngIf="!IS_TW_SELECT_DISABLED">
                        <ng-container [ngSwitch]="!!checkActiveWindow(window)">
                          <button *ngSwitchCase="true" class="button is-small is-info">
                            Selected
                          </button>
                          <button *ngSwitchCase="false" class="button is-small" (click)="selectTestWindowFocus(window)">
                            Not Selected
                          </button>
                        </ng-container>
                      </td>
                    </tr>
                  </ng-container>
                </table>
                <div style="margin-top: 2em;">
                  
                  <div style="margin-bottom: 2em;">
                    <button
                      class="button is-info is-small"
                      (click)="createTestWindow()"
                    >
                      Create New Administration Window
                    </button>
                  </div>

                  
                  <div>
                    <h2>
                      Production Registration Summaries
                    </h2>

                    <h4>
                      <a 
                      class="summary-link" 
                      (click)="goToTWSummaryPageURL(false)">
                       FSA 
                      </a>
                    </h4>

                    <h4>
                      <a 
                      class="summary-link" 
                      (click)="goToTWSummaryPageURL(true)">
                       GRAD 
                      </a>
                    </h4>

                  </div>

                  <!-- Commenting out for now; links for summaries for BC using this view are not configured yet. Adding links to summaries instead -->
                  <!-- <button class="button is-info" style="margin-bottom: 1em;" (click)="loadAllWinSummary()" [disabled]="isLoadingAllWinSummary">
                    Load Administration Summary for All Windows
                  </button>
                  <div *ngIf="isLoadingAllWinSummary" class="notification is-warning">
                    Loading all window summary...
                  </div>
                  <div *ngIf="allWinSummaryRecords">
                    <ag-grid-angular
                      class="ag-theme-alpine ag-grid-fullpage"
                      style="max-height: 30em;"
                      [rowData]="allWinSummaryRecords"
                      [gridOptions]="allWinGridOptions"
                      [enableCellTextSelection]="true"
                    ></ag-grid-angular>
                    <div style="margin-top:1em;">
                      <button class="button" (click)="downloadAllWinSummary()">
                        Export CSV
                      </button>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>

            <ng-container *ngIf="isWindowSelected()">

              <div class="card dashboard-card" *ngIf="isShowSummaries()">
                <div class="image-holder">
                  <img src="https://mathproficiencytest.ca/img/account-icon.png">
                </div>
                <div class="table-holder">


                  <div class="lead">
                    <div>Summaries</div>
                    <div class="buttons">
                      <!-- <button class="button is-small">Export to Excel</button> -->
                    </div>
                  </div>
                  <div>
                    <!-- Click on the link to the boards and schools to further filter the d -->
                  </div>
                  <table>
                    <tr>
                      <th>Account Type</th>
                      <ng-container *ngIf="!IS_COUNTS_DISABLED">
                        <th *ngFor="let col of userGroupSummaryColumns">
                          <tra [slug]="col.caption"></tra>
                        </th>
                      </ng-container>
                    </tr>
                    <tr *ngFor="let userGroupType of userGroupTypes">
                      <td [ngSwitch]="userGroupType.caption" class="is-data-label">
                        <a *ngSwitchDefault [href]="getRawLink(userGroupType)" target="_blank">
                          <tra [slug]="userGroupType.caption"></tra>
                        </a>
                        <a *ngSwitchCase="'Schools'" routerLink="/en/test-ctrl/school">
                          <tra [slug]="userGroupType.caption"></tra>
                        </a>
                        <a *ngSwitchCase="'Boards'" routerLink="/en/test-ctrl/school-boards">
                          <tra [slug]="userGroupType.caption"></tra>
                        </a>
                        <a *ngSwitchCase="'Classes'" routerLink="/en/test-ctrl/school-classes">
                          <tra [slug]="userGroupType.caption"></tra>
                        </a>
                        <a *ngSwitchCase="'Teachers'" routerLink="/en/test-ctrl/school-teachers">
                          <tra [slug]="userGroupType.caption"></tra>
                        </a>
                        <a *ngSwitchCase="'Students'" routerLink="/en/test-ctrl/school-students">
                          <tra [slug]="userGroupType.caption"></tra>
                        </a>
                      </td>
                      <ng-container *ngIf="!IS_COUNTS_DISABLED">
                        <td *ngFor="let col of userGroupSummaryColumns" class="has-data">
                          <!-- {{getColValue(userGroupType.id, col.id)}} -->
                          <a [routerLink]="getColLink(userGroupType.id)" [queryParams]="{ order: col.id }">
                            {{getColValue(userGroupType.id, col.id)}}
                          </a>
                        </td>
                      </ng-container>
                    </tr>
                  </table>

                  <!-- <div  style="margin-top:2em;">
                    <strong>Technical Readiness Tracker</strong>
                  </div>
                  <table >
                    <tr>
                      <th>Tally</th>
                      <th>SchName</th>
                      <th>SchMident</th>
                      <th>PrincipalFirstName</th>
                      <th>PrincipalEmail</th>
                    </tr>
                    <tr *ngFor="let school of g9Summary.tech_readi" [class.is-strong]="school.tally==10">
                      <td>{{school.tally}}/10</td>
                      <td>{{school.SchName}}</td>
                      <td>{{school.SchMident}}</td>
                      <td>{{school.PrincipalFirstName}}</td>
                      <td>{{school.PrincipalEmail}}</td>
                    </tr>
                  </table> -->
                  <div class="summary-timestamp">{{renderTimestampAsUIString()}}</div>

                  <div *ngIf="twFiles && twFiles.length">
                    <hr>
                    <h4>Administration Window Extracts</h4>
                    <table>
                      <tr *ngFor="let file of twFiles">
                        <td>{{file.slug}}</td>
                        <td>
                          <a [href]="file.url" target="_blank">
                            {{file.file_name}}
                          </a>
                        </td>
                        <td>{{file.created_on}}</td>
                      </tr>
                    </table>
                  </div>
                  
                </div>
              </div>

              <div *ngIf="isShowDataDownloads()" class="card dashboard-card" >
                <div class="image-holder">
                  <img src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/excel/1606094586078/excel.png">
                </div>
                <div class="table-holder">
                  <div class="lead">Data Downloads</div>
                  <div>
                    The links below can be used to download the relevant data. Data mapping is consistent with the codebook. The only data frames that are currently avaialble are the ones marked with the spreadsheet icon (<i style="color: #15aabf;" class="fas fa-file-excel"></i>).
                  </div>
                  <div>
                    <strong>Filter is set to the active test window.</strong>
                  </div>
                  <table>
                    <ng-container *ngFor="let section of codebookTable.sections">
                      <tr class="lead-row">
                        <td>{{section.id}}</td>
                        <td [ngSwitch]="!!section.link" class="lead-caption">
                          <span *ngSwitchCase="false">{{section.caption}}</span>
                          <a *ngSwitchCase="true" target="_blank" [href]="section.link">{{section.caption}}</a>
                        </td>
                        <td></td>
                      </tr>
                      <tr *ngFor="let dataFrame of section.dataFrames">
                        <td>{{section.id}}.{{dataFrame.id}}</td>
                        <td [ngSwitch]="!!dataFrame.isEnabled">
                          <div (click)="downloadTransferTable(dataFrame, section.id)" *ngSwitchCase="true" target="_blank" style="cursor:pointer; text-decoration:underline;">
                            <i style="color: #15aabf; margin-right:0.5em;" class="fas fa-file-excel"></i>
                            {{dataFrame.caption}}
                          </div>
                          <span *ngSwitchCase="false">
                            {{dataFrame.caption}}
                          </span>
                        </td>
                        <td>
                          <div [ngClass]="{ 'td-line-breaks': dataFrame.id == 9.4 }">
                            {{dataFrame.description}}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3"></td>
                      </tr>
                    </ng-container>
                  </table>
                </div>
              </div>

            </ng-container>


          </div>

  
        </div>

      </div>

    </div>
  </div>
  <footer [hasLinks]="true"></footer>
</div>
