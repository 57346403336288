import { Type } from "@angular/core";
import { SCALE_MODES } from "pixi.js";
import { CustomButtonPos, ItemType } from "../../ui-item-maker/models";
import { ISquiggleData } from "../element-render-custom-interaction/controllers/util/pixi-graph";
import { IContentElementCustomInteraction, IEntryStateCustomInteraction } from "../element-render-custom-interaction/model";
import { IContentElementDndDraggable, IElementPos } from "../element-render-dnd/model";
import { PubSubTypes } from "../element-render-frame/pubsub/types";
import { IContentElementDynamicImage, IContentElementImage, IContentElementImageSubText, ImageStates } from "../element-render-image/model";
import { IContentElementOption, McqDisplay } from "../element-render-mcq/model";
import { OrderMode } from "../element-render-order/model";
import { IContentElementText } from "../element-render-text/model";
import { IQPubSubPayload } from "../question-runner/pubsub/question-pubsub";

export interface ICustomTaskSet {
  __id?: string;
  __backup__id?: string;
  __backup__userDisplayName?: string;
  name?: string;
  order?: number;
  taskId?: string; // cross link to collection `tasks`
  project?: string;
  isReady?: boolean;
  isProtected?: boolean;
  labelCounter?: number;
  isArchived?: boolean;
  questions?: IQuestionConfig[] | string;
  creatorUid?: string;
  timeCreated?: any;
  timeLastSaved?: any;
  schemaVersion?: number;
  lastTouchedBy?: string;
  assessmentFrameworkId?: string;
}

export interface ICustomTaskTag {
  __id?: string;
  caption: string;
  customTaskSetId: string;
  localTaskId: number;
  createdBy: string;
  timestamp: any;
}

export enum Direction {
  UP = "up",
  DOWN = "down",
  LEFT = "left",
  RIGHT = "right"
}

export interface ICustomTaskComment {
  __id?: string;
  caption: string;
  customTaskSetId: string;
  localTaskId: number;
  createdByName: string;
  createdByUid: string;
  timestamp: any;
}

export interface IReadSelProps {
  [key:string] : {
    isVisible: boolean
  }
}

export interface IVoiceover {
  url?: string;
  script?: string;
  fileType?: string;
};
export interface IQuestionConfig {
  id?: number;
  assetId?: number;
  content: IContentElement[];
  label: string;
  updated_on?: string;
  testLabel: string;
  isReady?: boolean;
  readSel?: string;
  caption?: string;
  // captionVoiceover?: {[key:number]: IVoiceover};
  points?: string;
  readSelections?: string[];
  readSelProps?: IReadSelProps;
  isInfoSlide?: boolean;
  isNotScored?: boolean;
  notes?: string;
  taskId?: string;
  isReadingSelectionPage?: boolean; // intended to be temporary
  readingSelectionCaption?: string;
  isReqFill?: boolean;
  ishidePoints?: boolean;
  reqFillEntries?: string;
  reqFillMsg?: string;
  isReadingSelectionsAlwaysShown?: boolean;
  isStartHalf?: boolean;
  localTaskId: number;
  entryIdCounter?: number;
  entryOrder?: number[];
  quadrantFreq?: number;
  estimatedExposure?: number;
  isLocked?: boolean;
  isTrackingChanges?: boolean;
  isEditing?: boolean;
  langLink: IQuestionConfig;
  voiceover: IVoiceover;
  __changeCounter?: number; // only used for meta changes at first
  meta: any;
  comments?: any[];
  type?: ItemType;
  versionId?: number;
  backgroundImage?: IContentElementImage;
  bannerImage?: IContentElementImage;
  bannerSubtitle?: IContentElementText;
  bannerSubtitleMarginBottom?: number;
  bannerTitle?: IContentElementText,
  showBannerHr?: boolean;
  bannerHrColor?: string;
  bannerOverlay?: IContentElementImage;
  useCustomPrev?: boolean,
  useCustomNext?: boolean,
  customPrevBgColor?: string,
  customPrevFgColor?: string,
  customNextBgColor?: string,
  customNextFgColor?: string,
  customPrevText?: string,
  customNextText?: string,
  customButtonPos?: CustomButtonPos,
  customPrevBold?: boolean,
  customNextBold?: boolean,
  customButtonIndent?: number,
  isQuestionnaire?: boolean
}

export interface IQuestionScoringInfo {
  item_id: number;
  lang: string;
  is_human_scored: boolean;
  scales: {
    scale_id: number,
    score_profile_id: number,
  }[];
  [key: string]: any;
}

export interface IScoringCodes {
  id : number,
  short_name: string,
  description_en: string,
  description_fr: string,
  type_slug: string
}

export interface IQuestionImpressionConfig {
  question_id: number;
  element_type: string;
  total_unique_user_impressions: number;
}

export interface IAssetImpressionConfig {
  asset_id: number;
  asset_version_id: number;
  element_type: string;
  item_set_id: number;
  question_id: number;
}

export interface ISequenceConfig extends IQuestionConfig {
  children: IQuestionConfig[];
  isCollapsed: boolean;
}

export interface IContentElement {
  elementType: string | ElementType;
  isShowAdvancedOptions?: boolean;
  entryId?: number;
  _changeCounter?: number;
  isStateEnabled?: boolean;
  assetId?: number;
  assetVersionId?: number;
  colourScheme?: IContentElementColorPanel;
  voiceover?: any;
  canvasScaleWidth?: number;
  canvasScaleHeight?: number;
  _isCollapsed?: boolean;
  isNoInvertOnHiContrast?: boolean;
  url?: string;
  transcriptUrl?: string;
  format?: string;
  displayStyle?: string;
  paragraphStyle?: string;
  images?: any;
}

export const defaultEditExcludeFields = ['_changeCounter', 'elementType', 'entryId'];


export interface PieSlice {
  colour: number;
  correctAns?: number;
  caption?: string;
  initialValue?: number;
  targetEntryId?: number
}
export interface IContentElementPieChart extends IContentElementCustomInteraction {
  granularity: number;
  totalPieValue: number;
  slices: PieSlice[];
  roundingAllowance: number;
  pieRadius: number;
}


export interface IContentElementPixiGraph extends IContentElementCustomInteraction {
  xAxisLabel?: string;
  yAxisLabel?: string;
  xLength: number;
  yLength: number;
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
  xOrigin?: number;
  yOrigin?: number;
  yGap?: number;
  xGap?: number;
  scaleUnit?: number;
  isGraphArrowsDisabled?: boolean;
  axisThickness?: number;
  lineThickness?: number;
  axisUnitFontSize?: number;
  axisLabelFontSize?: number;
  // xInc: number;
  // yInc: number;
  distanceBetweenLabelX?: number;
  distanceBetweenLabelY?: number;
  textRes?: number;
  isHideAxisUnitLabels?: boolean;
  isSnapping?: boolean;
  snapScale?: number;
  isSquiggled?: boolean;
  squiggleData?: ISquiggleData; 
  isSmallGridEnabled?: boolean;
  xSmallGrid?: number;
  ySmallGrid?: number;
  backgroundColor?: string;
  backgroundColorAlpha?: number;
}

export interface IContentElementGridFill extends IContentElementPixiGraph {
  defaultX: number;
  defaultY: number;
  defaultWidth: number;
  defaultHeight: number;
  isDefaultBorder: boolean;
  defaultBorderThick: number; 
  defaultBorderColor: string;
  defaultFillColor: string;
  defaultFillColorAlpha?: number;
  value: number;
  tolerance: number;  
}

export interface IContentElementGridScatterPlot extends IContentElementPixiGraph {
  pointRadius: number;
  pointColor: string;
  pointColorAlpha: number;
  isPointCircled: boolean;
  circleRadius: number;
  circleBorder: number;
  circleColor: string;
  correctPoints: {[name:string]: number[]}
}
export interface IScoredResponse {
  entryId?: number;
  scoreWeight?: number;
  enableProportionalScoring?: boolean;
  useDistractorAsResponseSlug?: boolean;
  isOptional?: boolean;
}

export interface TextParagraph {
  caption?: string,
  paddingRight?: number,
  paddingLeft?: number,
  paragraphSpaceAfter?: number,
  numberingOverride?: string,
}


export interface IContentElementColorPanel {
  backgroundColor?: string;
  textColor?: string;
}




export type QuestionState = Map<number, any>;



export enum FontFamily {
  ARIAL           = "Arial",
  BIG_CASLON      = "Big Caslon",
  BODONI_MT       = "Bodoni MT",
  BOOK_ANTIQUA    = "Book Antiqua",
  BOOKMAN         = "Bookman",
  CALIBRI         = "Calibiri",
  GILL_SANS       = "Gill Sans",
  HELVETICA       = "Helvetica",
  LUCIDA_SANS     = "Lucida Sans",
  NOTO            = "Noto",
  OSWALD          = "Oswald",
  TIMES_NEW_ROMAN = "Times New Roman",
  VERDANA         = "Verdana",
}

export interface OrderCombination {
  order: string
}
export interface IRatioTermConfig {
  value: string;
}
export enum NumberTolerance {
  ALTVALUE = "altvalue",
  DECIMALS = "decimals",
  MINMAX = "minmax",
  PROXPERC = "proxperc" // proximity by percentage
}
export interface INumberTolerance {
  toleranceTypeId: NumberTolerance;
  numDecimals?: number;
  min?: number;
  max?: number;
  proxPerc?: number;
}
export interface IContentTextBlock {
  value: IContentElementText;
}

export interface IElementTypeDef {
  id: ElementType | string;
  caption: string;
  icon: string;
  isInteractive?: boolean;
  isKeyboardAccessible?: boolean;
  isDisabled?: boolean;
  feedProps?: { [key: string]: any }; // used for the dropdown creators where the block type is overloaded and the config is what differentiates it
  isPrintFriendly?: boolean,
  editingInfo: IElementTypeEditInfo
}

export interface IElementTypeEditInfo {
  superType?: ElementType | string;
  editExcludeFields: string[]|{[key:string]:string[]}
  editTextFields?: string[]
}

export enum ElementType {
  TEXT = "text",
  TEXT_LINK = "text_link",
  MATH = "math",
  IMAGE = "image",
  IMAGE_SUBTEXT = "image_subtext",
  DYNAMIC_IMAGE = "dynamic_image",
  MCQ = "mcq",
  DND = "dnd",
  ORDER = "order",
  GRAPHING = "graphing",
  TABLE = "table",
  INPUT = "input",
  VIDEO = "video",
  AUDIO = "audio",
  MIC = "mic",
  CANVAS = "canvas",
  CANVAS_BOOKMARK = "canvas_bookmark_element",
  CAMERA = "camera",
  DOC_LINK = "doc_link",
  UPLOAD = "upload",
  HOTSPOT = "hotspot",
  HOTTEXT = "hottext",
  MATCHING = "matching",
  READER = "reader",
  IFRAME = "iframe",
  FRAME = "frame",
  SBS = "sbs",
  SOLUTION = "solution",
  SELECT_TABLE = "select_table",
  CUSTOM_MCQ = "custom_mcq",
  INSERTION = "insertion",
  GRAPHICS = "Graphics",
  GROUPING = "Grouping",
  SELECT_TEXT = "select_text",
  ANNOTATION = "annotation",
  MOVEABLE_DND = "moveable_dnd",
  RESULTS_PRINT = "results_print",
  VALIDATOR = "validator",
  CUSTOM_INTERACTION = "custom_interaction",
  BOOKMARK_LINK = "bookmark_link",
  OPTION = 'option',
  MCQ_OPTION = 'mcq_option',
  CUSTOM_MCQ_OPTION = 'custom_mcq_option',
  ORDER_OPTION = 'order_option',
  SELECTABLE_TEXT = 'selectable_text',
  TABLE_TEXT = 'table_text',
  TEXT_PARAGRAPH = 'text_paragraph',
  BLANK = 'BLANK',
  SELECT_TABLE_CELL = 'select_table_cell'
}

export interface IEntryState {
  type: string;
  isCustomGrading?: boolean;
  isCorrect?: boolean;
  isStarted: boolean;
  isFilled: boolean;
  isResponded?: boolean;
}

export const getElementWeight = (element: IScoredResponse) => {
  const weight = element.scoreWeight;
  if (weight === 0) {
    return weight;
  }
  if (!weight) {
    return 1;
  }
  return +weight;
};

export const roundNumeric =(num: number) => { 
  return Math.round(100*num)/100; // fixed to 2 decimal places for now
}

export const shuffle = (arr: any[]) => {
  for (let i = 0; i < arr.length; i++) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
  }
  return arr;
};

export const countDecimals = (number) => {
  if(Math.floor(number) == number) return 0;
  return number.toString().split('.')[1].length || 0;
}

export const intializeDefault = (defaults, object) => {
  for (let def of Object.keys(defaults)) {
    if (object[def] == null) {
      object[def] = defaults[def];
    }
  }
}

export enum ScoringTypes {
  AUTO = "AUTO",
  REVIEW = "REVIEW",
  MANUAL = "MANUAL"
}
export interface IEntryStateScored extends IEntryState {
  score: number;
  weight: number;
  scoring_type: ScoringTypes;
  isScoringDisabled?: boolean
}
export interface IEntryStatePieChart extends IEntryStateCustomInteraction {
  answers: number[]
}
export interface IEntryStateGridFill extends IEntryStateCustomInteraction {
  value: number;
  shapeBoundries: any;
}

export interface IEntryStateGridScatterPlot extends IEntryStateCustomInteraction {
  value: Map<string, number[]>;
}
