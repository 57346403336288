export interface IIdentifiedEntry {
    id: number | string,
    [key:string]: any
}

export interface IDataDefinition extends IIdentifiedEntry {
    label: string, // translation slug
}
export interface IMultiSelect extends IDataDefinition{
    tag?: string,
}
export interface IStudent_School_Enrol_Type extends IDataDefinition{
    isSchoolEditable?: boolean,
}

export interface IClassroom {
    id:number,
    class_code:string,
    educator_id?:number,        
    educator:string,        
    course:string | number,
    isFrench?:string,
    semester:string,
    remote_courses_sdc_student_rem_inp_1: boolean,
    remote_courses_sdc_student_rem_inp_2: boolean,
    students:number,
    onboarding:number,
    assessment:number,
    teachers_uids?: number[],
    openAssessments: any[],
    recentAssessments: any[],
    __isSelected?: boolean, // client-only
    __multipleCourses?: boolean, // client only
    [key:string]: any,
}
export interface ISemester{
    id:number,
    foreign_scope_id:number,
    foreign_id:number,
    label:string,
    testWindowId:number,
}
export  interface ITeacher {
    id:number,
    first_name:string,
    last_name:string,
}
export interface ISession {
    slug: string;
    id:number;
    school_name:string;
    invigilator:string;
    classroom_id:string;
    firstName:string,
    lastName:string,
    classCode:string;
    description:string;
    students:number;
    //times:string[];
    startTime:string;
    submissions:number;
    submissions_1?:number;
    endTime:string;
    closed_on?: string;
    isConfirmed?:boolean;
    isclosed?:boolean;
    status:string;
    email?:string;
    invit_email?:string;
    semester?:any;
    semester_label?:string[];
    __isSelected?: boolean; // client-only
    uuid?: string;
    session_a?:{
        [prop:string]: any
    }
    session_b?:{
        [prop:string]: any
    };
    paymentStatus?: string;
}

export enum SessionModal {
    NEW_SESSION      = 'new-session',
    EDIT_SESSION     = 'edit-details',
    STUDENTS_SESSION = 'student-counts',
    SESSION_PAYMENT_OVERVIEW = 'session-payment',
    SESSION_PAYMENT_STRIPE_OVERVIEW = 'session-payment-stripe',
    SESSION_PAYMENT_ALTERNATIVE_OVERVIEW = 'session-payment-alternative'
}

export enum SessionStatus {
    PENDING = 'pending',
    ACTIVE  = 'active'
    //COMPLETED = 'completed'
}

export enum PurchaseModal {
    PURCHASE_REFUND_POLICY = 'purchase-refund-policy',
    ALTERNATIVE_PAYMENT_APPROVE = 'alternative-payment-approve',
}

export enum IEQAOStudentAccommodationFilter {
    IEP = 'IEP',
    BRAILLE = 'BRAILLE',
    ANY_ASSISTIVE = 'ANY_ASSISTIVE'
}

export interface IStudentAccount {
    id: number;
    uid?: number;
    is_submitted?: string;
    submitted_test_session_id?: number;
    test_attempt_id?:number;
    first_name: string;
    last_name: string;
    group_id?: number; // which group ID is this referencing? I would think school membership
    email?: string; // not applicable to G9
    student_pen?: string; // deprecated... should be bc_student_pen
    eqao_student_gov_id?: string; // replaces `student_pen` in current implementation... for G9 this maps to `sdc_OEN`
    eqao_student_school_id?: string; // replaces `student_pen` in current implementation... for G9 this maps to `sdc_OEN`
    eqao_student_school_enrol_type?: number | string,
    test_sessions?: number[];
    __isSelected?: boolean; // client-only
    lang?: string; // read from the school info
    classCode?: string; // deprecated
    eqao_g9_class_code?: any // to replace `classCode`
    eqao_g9_course?: string // to replace `classCode`
    eqao_g9_enrolled_ontario?: boolean, // maybe it should be a number
    schoolAdminOverrides?: {
        [prop:string]: {original: any, updated:any}
    }
    eqao_g9_gender?: number,
    
    [key:string]: any,
  }
  
  export interface IStudentTestSession {
    id: number;
    test_window_id: number;
    test_window_title: string;
    date_time_start: string;
    room: string;
    campus_building: string;
    city: string;
  }
  
  export interface IValidationError {
    prop: string;
    message: string;
  }
  export interface IValidationResult<T> {
    obj: T;
    errors: IValidationError[];
  }
  export interface IValidationResults<T> {
    // passed: T[];
    successCreate: T[];
    successUpdate: T[];
    noChange: T[];
    failed: T[];
    results: IValidationResult<T>[]; 
  }