// app services
// import { IContentElementMcq, IContentElementMcqOption } from "src/app/ui-testrunner/element-render-mcq/model";
import { IContentElementMcq, IContentElementMcqOption } from "src/app/ui-testrunner/element-render-mcq/model";
import { checkElementIsEntry, getElementChildren, IQuestionConfig } from ".";
import { IContentElement, ElementType, IScoredResponse } from "../../../ui-testrunner/models";
import { IAssessmentFrameworkDimensionDetail } from "./assessment-framework";


export const collectElements = (elements: IContentElement[], collector?:Set<IContentElement>) => {
  if(!collector) {
    collector = new Set<IContentElement>();
  }
  elements.forEach( element => {
    collector.add(element);
    collectElements(getElementChildren(element), collector);
  })
  return collector;
}

export const identifyQuestionResponseEntries = (elements:IContentElement[], collector?:IContentElement[], isAutoScoreable?:boolean) => { // , extraGrabber?:()=>any[]
  if (!collector){
    collector = [];
  }
  elements.forEach(element => {
    if (checkElementIsEntry(element, isAutoScoreable)){
      collector.push(element);
    }
    identifyQuestionResponseEntries(getElementChildren(element), collector, isAutoScoreable);
  });
  return collector;
}

export const extractQuestionAssetVersionIds = (elements:IContentElement[], collector:number[]) => {
  elements.forEach(element => {
    if (element.assetVersionId){
      collector.push(element.assetVersionId);
    }
    extractQuestionAssetVersionIds(getElementChildren(element), collector);
  });
  return collector;
}

export const collectAndEnsureEntryIds = (elements:IContentElement[]) => {
  const entries = collectElements(elements);
  // what do we have?
  let maxEntryId = 0;
  let nonIndicatedEntries:IContentElement[] = [];
  let overlappingEntries:IContentElement[] = [];
  const entryIdsSeen = new Map();
  entries.forEach(entry => {
    if (entry.entryId){
      const entryId = +entry.entryId
      if (entryIdsSeen.get(entryId)){
        overlappingEntries.push(entry);
      }
      else{
        maxEntryId = Math.max(maxEntryId, entryId);
        entryIdsSeen.set(entryId, true);
      }
    }
    else{
      nonIndicatedEntries.push(entry);
    }
  });
  return {
    nonIndicatedEntries,
    overlappingEntries,
    maxEntryId
  }
}

export const ensureResponseEntryIds = (question:IQuestionConfig) => {
  const elements = question.content;
  const tracker = collectAndEnsureEntryIds(elements);
  if (tracker.overlappingEntries.length){
    console.warn('Some entry IDs were duplicated through a manual editing of the item source. These are being automatically fixed.');
  }
  const elementsToUpdate = tracker.nonIndicatedEntries.concat(tracker.overlappingEntries)
  applyEntryIds(elementsToUpdate, tracker.maxEntryId);
  const entryIds = identifyQuestionResponseEntries(elements, []).map(element => element.entryId);
  question.entryOrder = entryIds;
}

export const applyEntryIds = (elements:IContentElement[], entryIdCount:number=0) => {
  elements.forEach(element => {
    entryIdCount += 1;
    element.entryId = entryIdCount;
  })
  return entryIdCount;
}

export const getQuestionConfiguredEA = (
  questionContent:IContentElement[], 
  param:IAssessmentFrameworkDimensionDetail, 
  context:{
    question:IQuestionConfig, 
    questionsMissingParam?: IQuestionConfig[]
    questionsNotMcq?: IQuestionConfig[]
    questionsOutRange?: IQuestionConfig[]
    questionsNoEntry?: IQuestionConfig[]
    questionsMultiEntry?: IQuestionConfig[]
    questionsNoAccepted?: IQuestionConfig[]
    questionsMultiAccepted?: IQuestionConfig[]
  }
  ) => {
    const entries = identifyQuestionResponseEntries(questionContent, []);
    const trackQuestionIssue = (qIssueList:IQuestionConfig[], question:IQuestionConfig) => {
      if (qIssueList){
        if (qIssueList.indexOf(question) === -1){
          qIssueList.push(question)
        }
      }
    }
    if (entries.length == 0){
      trackQuestionIssue(context.questionsNoEntry, context.question);
      return;
    }
    if (entries.length > 1){
      trackQuestionIssue(context.questionsMultiEntry, context.question);
    }
    const entry = entries[0];
    if (entry.elementType !== ElementType.MCQ){
      console.log('entry.elementType', entry.elementType)
      trackQuestionIssue(context.questionsNotMcq, context.question);
      return;
    }
    const mcqEntry = <IContentElementMcq> entry
    const selectedIndexes = [];
    mcqEntry.options.forEach((option:IContentElementMcqOption, i:number) => {
      if (option.isCorrect){
        selectedIndexes.push(i);
      }
    });
    let isUndefined = false;
    const selectedOptions = selectedIndexes.map(i => {
      if (param.config.tags[i]){
        return param.config.tags[i].code;
      }
      else{
        isUndefined = true;
      }
    })
    if (isUndefined){
      trackQuestionIssue(context.questionsOutRange, context.question);
      return;
    }
    if (selectedOptions.length === 0){
      // console.log('questionsNoAccepted', context.question.label, selectedIndexes, mcqEntry.options)
      trackQuestionIssue(context.questionsNoAccepted, context.question);
    }
    if (selectedOptions.length > 1){
      trackQuestionIssue(context.questionsMultiAccepted, context.question);
    }
    return selectedOptions.join(',');
  }

  export const updateQChangeCounter = (question) => {
    if (!question.__changeCounter) {
      question.__changeCounter = 0;
    }
    question.__changeCounter ++;
  }
  
  export const updateCurrentQuestionExpectedAnswer = (currentQuestion, currentMetaContainer, paramsToUpdate) => {
    const entries = identifyQuestionResponseEntries(currentQuestion.content, []);
    // for each entry, get indexes of the isCorrect, use the values from the tags, and apply it as the param value in the question meta
    console.log('_updateCurrentQuestionExpectedAnswer', entries);
    if (entries.length > 1) {
      console.warn('Too many response entries in the item.');
    }
    if (entries.length === 1) {
      const entry = entries[0];
      if (entry.elementType !== ElementType.MCQ) {
        console.warn('Cannot update single entry response key for non-MCQ.');
      } else {
        const mcqEntry = <IContentElementMcq> entry;
        const selectedIndexes = [];
        mcqEntry.options.forEach((option: IContentElementMcqOption, i: number) => {
          if (option.isCorrect) {
            selectedIndexes.push(i);
          }
        });
        paramsToUpdate.forEach(param => {
          if (param.config && param.config.tags) {
            let isUndefined = false;
            const selectedOptions = selectedIndexes.map(i => {
              if (param.config.tags[i]) {
                return param.config.tags[i].code;
              } else {
                isUndefined = true;
              }
            });
            if (isUndefined) {
              currentMetaContainer.meta[param.code] = '__INVALID_OPTION';
            } else {
              currentMetaContainer.meta[param.code] = selectedOptions.join(',');
            }
            console.log(param.code, currentMetaContainer.meta);
          }
        });
        updateQChangeCounter(currentQuestion);
      }
    }
  }