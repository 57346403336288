import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ItemComponentEditService } from '../../ui-item-maker/item-component-edit.service';
import { EditViewMode } from '../../ui-item-maker/item-set-editor/models/types';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { AuthScopeSetting, AuthScopeSettingsService } from '../../ui-item-maker/auth-scope-settings.service';
import { elementIconById } from '../../ui-item-maker/item-set-editor/models';
import { DrawingLogService } from '../drawing-log.service';
import { DisplayMode } from '../element-render-audio/model';
import { IContentElementCanvas } from '../element-render-canvas/model';
import { IContentElementFrame } from '../element-render-frame/model';
import { ElementType } from "../models";
import { IContentElement, IQuestionConfig } from '../models/index';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { TextToSpeechService } from '../text-to-speech.service';
import { QuestionRunnerLayoutService } from '../question-runner-layout.service';

@Component({
  selector: 'question-runner',
  templateUrl: './question-runner.component.html',
  styleUrls: ['./question-runner.component.scss']
})
export class QuestionRunnerComponent implements OnInit, OnChanges {

  @Input() currentQuestion:IQuestionConfig;
  @Input() currentQuestionIndex:number = 0;
  @Input() totalQuestions:number = 4;
  @Input() questionState:any;
  @Input() isSubmitted:boolean;
  @Input() containingWidth:number = 40;
  @Input() containingWidthSpill:number;
  @Input() allowSubtitles: boolean = false;
  @Input() allowTranscripts: boolean = false;
  @Input() allowAudioPlaybackSpeed: boolean = false;
  @Input() allowVideoPlaybackSpeed: boolean = false;
  @Input() isPrintMode: boolean = false;
  @Input() selectedEditEntry: any = null; 
  @Input() isSkipAnimatedEntry:boolean; 
  @Input() frameWorkTags:{slug:string}[];

  questionTextSize = new FormControl(1);
  questionAudioUrl:SafeResourceUrl;
  questionPubSub:QuestionPubSub;
  DisplayMode = DisplayMode; 


  constructor(
    private textToSpeech:TextToSpeechService,
    private authScope: AuthScopeSettingsService,
    private whitelabel: WhitelabelService,
    private bufferedLog: DrawingLogService,
    private itemComponentEdit: ItemComponentEditService,
    private questionRunnerLayout: QuestionRunnerLayoutService
  ) { }

  
  ngOnInit() {
    this.reset();
    this.questionRunnerLayout.reset()
  }
  
  private reset(){
    this.questionPubSub = new QuestionPubSub(this.isPrintMode, this.selectedEditEntry);
  }

  ngOnChanges(changes:SimpleChanges){
    if (changes.currentQuestion){
      this.bufferedLog.bufferedLog('QUESTION_RESET_PRE', {id: (this.currentQuestion || {}).id, questionState: this.questionState });
      this.reset();
      this.itemComponentEdit.reset();
      this.bufferedLog.bufferedLog('QUESTION_RESET_POST', {id:(this.currentQuestion || {}).id, questionState: this.questionState });
    }
    if(changes.isPrintMode || changes.selectedEditEntry) {
      this.reset();
    }
  }

  getZIndex(contentElement:IContentElement) {
    return this.questionRunnerLayout.id2ZIndex.get(contentElement.entryId)
  }

  isFadeInEntrance(){
    return this.authScope.getSetting(AuthScopeSetting.Q_FADE_IN_ENTRANCE);
  }

  isAudioPlayerShowing(){
    return this.textToSpeech.isActive;
  }

  getQuestionAudioUrl(){
    return this.currentQuestion?.voiceover?.url;
  }

  isInputLocked(){
    return this.isSubmitted;
  }

  ghostAdded = false;
  getPixelsPerEM() {
    const el = document.getElementById("ghost");
    if (!el) return 0;
    if (!this.ghostAdded) {
      document.body.appendChild(el);
      this.ghostAdded = true;
    }
    const width = el.offsetWidth;
    const len = width/10;
    //document.body.removeChild(el);
    return len;
  }

  showElement(contentElement:IContentElement) {
    if (!this.isPrintMode || contentElement.elementType == ElementType.RESULTS_PRINT) return true;
  }

  getScaleToFitStyle(element) {
    return{};
    /*const widthConstrainedElement = this.checkForElementWidthConstraint(element);
    if (!widthConstrainedElement && window.innerWidth<1000) {
      return true;
    }*/
    let style:any = {};
    const scalingBuffer = 0.95;
    if (element.elementType == ElementType.CANVAS) {
      const pixelsPerEm = this.getPixelsPerEM();
      if (pixelsPerEm==0) return style;
      let width = ((window.innerWidth-180)/pixelsPerEm)
      const box = document.getElementById("readingPassageSplit")
      const questionRunnerInReadingPassage = document.getElementById("questionRunnerInReadingPassage")
      if (questionRunnerInReadingPassage) {
        width = box.offsetWidth/pixelsPerEm;
      }
      if (element.width>Math.floor(width)) {
        style["font-size"] = Math.floor(width)/element.width*scalingBuffer+"em";
      }
      //console.log(style);
      //console.log(window.innerWidth)
    }
    style["line-height"]="1em"
    return style;
  }

  getIconByElementTypeId(elementTypeId:string){
    return elementIconById.get(elementTypeId);
  }

  checkForElementWidthConstraint(elementTypeId:ElementType){
    // deprecated
    if (!this.whitelabel.getSiteFlag('TEST_RUNNER_WIDTH_CONSTRAINT')) return false;

    switch (elementTypeId){ 
      case ElementType.SELECT_TABLE:
      case ElementType.ORDER:
      case ElementType.CANVAS:
      case ElementType.TABLE:
        return false;
      default: 
        return true;
    }
  }

  isHighContrastException(element:IContentElement) {

    const exceptionTypes: any[] = [ElementType.CANVAS, ElementType.GROUPING, ElementType.MCQ, ElementType.SOLUTION];
    if (exceptionTypes.includes(element?.elementType)) {
      return true;
    }
    return false;
  }


  computeAnimDelayAndDuration(index:number){
    let animDelayIncr = 250;
    let animDuration = 600;
    let animDelay = 0;
    for (let i=1; i<index; i++){
      animDelay += animDelayIncr;
      animDelayIncr *= 0.8;
      animDuration *= 0.8;
    }
    return {
      animDelay,
      animDuration,
    }
  }

  private computeFontScaleByBaseWidth(baseWidth:number){
    let fontScale = 1
    if (baseWidth && this.containingWidth < baseWidth){
      fontScale = this.containingWidth / baseWidth;
    }
    return fontScale;
  }

  getFontScale(element:IContentElement){
    let fontScale = 1;
    if (this.containingWidth){
      if (element.elementType === ElementType.CANVAS){
        const elementCanvas = <IContentElementCanvas> element;
        fontScale = this.computeFontScaleByBaseWidth(elementCanvas.width);
      }
      if (element.elementType === ElementType.FRAME){
        const elementFrame = <IContentElementFrame> element;
        fontScale = this.computeFontScaleByBaseWidth(elementFrame.width);
      }
    }
    return fontScale;
  }

  getElementStyle(element:IContentElement, index:number){
    const fontScale = this.getFontScale(element);
    const {animDelay, animDuration,} = this.computeAnimDelayAndDuration(index);
    const style = {
      'font-size': fontScale+'em',
    }
    if (this.isSkipAnimatedEntry){
      style['animation-delay'] = '0ms';
      style['animation-duration'] = '1ms';
    }
    else {
      style['animation-delay'] = animDelay+'ms';
      style['animation-duration'] = animDuration+'ms';
    }
    if (this.getZIndex(element)!=undefined) {
      style['position']='relative'
      style['z-index']=this.getZIndex(element)
    }
    return style
  }

  isElementVisible(contentElement) {
    return true;
    //return contentElement.elementType === ElementType.RESULTS_PRINT || !this.isPrintMode;
  }
}
