<div *ngIf="getImageUrl()" style="text-align: left;" [ngStyle]="getStyle()">
  <img 
    class="display-image"
    [src]="getImageUrl()" 
    [attr.alt]="element.altText"  
    [style.min-width.em]="getScale()" 
    [style.width.em]="getScale()"
    [attr.aria-hidden]="(element.altText && !!(element.altText.trim())) ? null : true"
    draggable="false"
    ondragstart="return false;"
  />
<!--
<div *ngIf="element.url" style="text-align: left;"
  [style.min-height.em]="(scaleFactor/100)*(element.scale)" 
  [style.height.em]="(scaleFactor/100)*(element.scale)"
  [style.min-width.em]="(scaleFactor/100)*(element.scale)" 
  [style.width.em]="(scaleFactor/100)*(element.scale)">
  <img [src]="getImageUrl()" style="height:100%;width:100%;" [attr.alt]="element.altText"/>-->
  <div class="multiline" *ngIf="showPrintModeAltText()">[[ALT_TEXT: {{element.altText}}]]</div>
</div>